<template>
  <b-card>

    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Nombre"
            label-for="nombre"
          >
            <b-form-input
              v-model="item.nombre"
              name="nombre"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            label="Tipo Ruta"
            label-for="tipo"
          >
            <b-form-input
              v-model="item.tipoRuta"
              name="nombre"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        <b-col
          :cols="item.tipoRuta === 'DIURNA'? 3: 6"
        >
          <b-form-group
            label="Encargado Ruta"
            label-for="tipo"
          >
            <v-select
              v-model="item.userEncargado"
              label="nombre"
              :options="usuarios"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <!--
        <b-col
          sm="4"
        >
          <b-form-group
            label="Municipio"
            label-for="municipio"
          >
            <b-form-select
              v-model="item.idMunicipio"
              :options="municipios"
              :disabled="true"
            />
          </b-form-group>
        </b-col>
        -->
        <b-col
          cols="3"
        >
          <b-form-group
            v-if="item.tipoRuta === 'DIURNA'"
            label="Fecha Programada"
            label-for="tipo"
          >
            <b-form-datepicker
              v-model="item.fechaProgramada"
              name="fechaProgramada"
              class="mb-1"
              :disabled="editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Rutas Principales"
            label-for="active"
          >
            <v-select
              v-model="localidadesPrincipales"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="localidades"
              label="name"
              multiple
              outline
              offset-y
              :selectable="() => !editable"
              @cuechange="(selection) => selectionChanged(selection)"
              @change="(selection) => selectionChanged(selection)"
            />
          </b-form-group>
        </b-col>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Rutas Secundarias"
            label-for="active"
          >
            <v-select
              v-model="localidadesSecundarias"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="localidades"
              label="name"
              multiple
              :selectable="() => !editable"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <br>
        </b-col>
        <b-col
          v-if="editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="editable=false"
          >
            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Editar</span>
          </b-button>
        </b-col>
        <b-col
          v-if="!editable"
          cols="12"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click="updateItem"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Actualizar</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="cancel"
          >
            <feather-icon
              icon="SlashIcon"
              class="mr-50"
            />
            <span class="align-middle">Cancelar</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  // BFormSelect,
  BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { getMunicipios } from '@/utils/localizacion'
import { getItem, saveItem } from '@/utils/catalogos'
import { formatDate } from '@/utils/fechas'
import { getUsersByCompany } from '@/utils/usuarios'
import { findInspecciones, updateCreateInspeccion } from '@/utils/inspecciones'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    vSelect,
    // BFormSelect,
    BFormDatepicker,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      catalogoName: 'Ruta',
      catalogType: 8,
      item: {},
      municipios: [],
      localidades: [],
      insepecciones: [],
      usuarios: [],
      localidadesPrincipales: [],
      localidadesSecundarias: [],
      editable: router.currentRoute.params.action !== 'Edit',
      option: [
        {
          title: 'ACTIVO',
          icon: 'ToggleRightIcon',
          value: true,
        },
        {
          title: 'INACTIVO',
          icon: 'ToggleLeftIcon',
          value: false,
        },
      ],
    }
  },
  async created() {
    const user = JSON.parse(localStorage.getItem('userData'))
    this.usuarios = await getUsersByCompany(user.idCompany) // await getUsersByCompanyRole(user.idCompany, 'inspecciones')
    this.usuarios.map(usuario => {
      // eslint-disable-next-line no-param-reassign
      usuario.nombre = `${usuario.firstName} ${usuario.firstSurname}`
      return usuario
    })
    await this.cargarItem()
    const result = await getMunicipios(false)
    this.municipios = []
    if (result != null) {
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in result) {
        this.municipios.push({ value: result[i].id, text: result[i].nombre })
      }
    }
  },
  methods: {
    selectionChanged(selection) {
      console.log('previous selection = ', this.previousSelection)

      let selected = null
      if (this.previousSelection.length < selection.length) {
        // eslint-disable-next-line prefer-destructuring
        selected = selection.filter(x => !this.previousSelection.includes(x))[0]
      } else if (this.previousSelection.length > selection.length) {
        // eslint-disable-next-line prefer-destructuring
        selected = this.previousSelection.filter(x => !selection.includes(x))[0]
      }

      console.log('selected = ', selected)

      this.previousSelection = selection
      console.log('selection = ', selection)
    },
    async updateItem() {
      const { active } = this.item
      this.item.principales = this.localidadesPrincipales
      this.item.secundarias = this.localidadesSecundarias
      delete this.item.localidades
      const result = await saveItem(this.catalogType, this.item, this.catalogoName, 2)
      if (result !== null) {
        delete this.item.localidadesOff
        delete this.item.principales
        delete this.item.secundarias
        delete this.item.fechaCreacion
        delete this.item.idMunicipio // TODO descomentar cuando si se seleccione el municio, y agregar en la BDD
        this.item.active = active
        const encargado = this.item.userEncargado
        this.item.userEncargado = this.item.userEncargado.id
        console.log('item a actualizar', this.item)
        await saveItem(this.catalogType, this.item, this.catalogoName, 2)

        // eslint-disable-next-line no-restricted-syntax
        for await (const inspeccion of this.insepecciones) {
          if (inspeccion.usuario.id !== encargado.id) {
            inspeccion.usuario = encargado
            await updateCreateInspeccion(inspeccion, 1)
          }
        }

        this.$router.replace('/inspecciones/rutas').then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: `${this.catalogoName} Actualizada!`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `${this.catalogoName} actualizada correctamente!`,
            },
          })
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `${this.catalogoName} no Actualizada`,
            icon: 'FrownIcon',
            variant: 'warning',
            text: `${this.catalogoName} no Actualizada!`,
          },
        })
      }
    },
    async cancel() {
      if (router.currentRoute.params.action === 'Edit') await this.$router.replace('/inspecciones/rutas')
      else await this.cargarItem()
    },
    async cargarItem() {
      try {
        const result = await getItem(this.catalogType, router.currentRoute.params.id)
        if (result !== null) {
          // eslint-disable-next-line prefer-destructuring
          this.item = result
          if (!this.item.idMunicipio) this.item.idMunicipio = 15
          if (this.item.fechaProgramada !== null) this.item.fechaProgramada = formatDate(this.item.fechaProgramada)
          // eslint-disable-next-line no-return-assign,no-param-reassign
          this.item.localidades.map(local => local.name = `${local.nombre} (${local.zona})`)
          this.localidadesPrincipales = this.item.localidades.filter(row => row.tipoRuta === 'PRINCIPAL')
          this.localidadesSecundarias = this.item.localidades.filter(row => row.tipoRuta === 'SECUNDARIO')
          this.localidades = this.item.localidades
          this.localidades = this.localidades.concat(this.item.localidadesOff)
          this.localidades.map(local => local.name = `${local.nombre} (${local.zona})`)
          // eslint-disable-next-line prefer-destructuring
          this.item.userEncargado = this.usuarios.filter(u => u.id === this.item.userEncargado)[0]

          this.insepecciones = await findInspecciones({
            where: {
              and: [
                { rutaId: this.item.id },
                { estado: { neq: 'FINALIZADA' } },
              ],
            },
            order: ['fechaCreacion DESC'],
          })
        }
      } catch (err) {
        console.error(`Error en cargar ${this.catalogoName}`, err)
        this.items = null
      }
      this.editable = router.currentRoute.params.action !== 'Edit'
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.vs--disabled {
  pointer-events:none !important;
  color: #000000 !important;
  cursor: not-allowed !important;
  background-image: none !important;
  background-color: #eef1f6 !important;
  border-color: #d1dbe5 !important;
}
</style>
